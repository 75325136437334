import React from "react";
import TrsLogo from "../../images/TRS_VirtualHealth_logo_CMYK-01_x300.png";
import Styled from "styled-components";


export default function TrsHeader(props) {
  const HeaderImg = Styled.header`
    background-image: url(${props.bgImage})
  `;
  return (
    <HeaderImg
      className="py-5 bg-image-full"
    >
      <img
        className="img-fluid d-inline-block trs-logo"
        src={TrsLogo}
        alt="trsLogo"
      />
    </HeaderImg>
)
}