import React from 'react';

const AppStoreBadge = ({ appId }) => {
  return (
    <a href={`https://apps.apple.com/us/app/${appId}`} target="_blank" rel="noreferrer">
      <img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"  height='68' alt="Download on the App Store" />
    </a>
  );
};

const PlayStoreBadge = ({ appId }) => {
  return (
    <a href={`https://play.google.com/store/apps/details?id=${appId}`} >
        <img src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
        alt='Get it on Google Play' height='100px' />
    </a>  
  );
};

export { AppStoreBadge, PlayStoreBadge };