import React from "react";
import BpMonitor from "./BpMonitor/BpMonitor";
import GlucoMeter from "./GlucoMeter/GlucoMeter";

function Main() {

  // Getting Data From Parameter // https://example.com/?t=some_value
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let device = params.device;
  //console.log(`device=${device} tranid=${tranId}`);

  return (
    <div className="App">
        {device === 'bpmonitor' &&  <BpMonitor device={device} /> }
        {device === 'glucometer' &&  <GlucoMeter device={device} /> }             
        {device === false &&  (
            <div className="splash">
              <h1>Invalid device parameter</h1>
          </div>
          )}             
    </div>
  );
}

export default Main;
