import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import backgroundImage from "../../images/slide2.png";
class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section section-lg"
          id="home"
          style={{
            overflow : "hidden",
            height : window.innerHeight * .50 + "px",
            backgroundImage : `url(${backgroundImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no",
            backgroundSize: "cover",
          }}
        >
          <div className="bg-overlay h-100"> </div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row>
                  <Col
                    lg={{ size: 8, offset: 2 }}
                    className="text-white text-center"
                  >
                    <h1 className="home-title text-rotate">
                      Ask Amy
                    </h1>
                    <p 
                      className="pt-3"
                      style={{
                        fontWeight: 100,
                        fontSize: "24px",
                        lineHeight: "36px"
                      }}
                      >
                      Amy is our Artificial Intelligence Assistant.  She can provide lots of useful information about our site and medical data.  She cannot provide medical advice.
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
