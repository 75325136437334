import React from "react";

export default function TrsFooter() {
  return (
    <footer className="py-3 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <a onClick={() => window.scrollTo(0, 0)} href="#top" id="footer-top" title="Back to top" style={{cursor: "pointer"}} className="btn btn-sm btn-outline-light mb-1">
                <i className="fa fa-angle-up"></i>
                <span className="small">Top</span>
              </a>{" "}
            </div>
            <div id="aboutus" className="col-sm-8 white">
              <h6>About Us</h6>
              <div className="about-foot">
                <p>
                  RediMD was founded in 2006 by a group of doctors working with
                  Texas Tech University Health Science Center. We provide
                  telemedicine in primary care, specialty care and workers'
                  compensation systems. We have grown into one of the largest
                  telemedicine providers with hundreds of thousands of
                  registered patients and have won many innovation awards over
                  the years including a 2018 TASB innovation award for our
                  worker's compensation program.{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="white">
                <h6>Contact Us</h6>
                <p>
                  <span className="fa fa-phone"></span>{" "}
                  <a className="white" href="tel:855-942-4900">
                    855-942-4900
                  </a>
                </p>
                <p>
                  <span className="fa fa-envelope"></span>{" "}
                  <a className="white" href="mailto:redimdsupport@redimd.com">
                    redimdsupport@redimd.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <p className="m-0 text-center text-white">
            Copyright &copy; RediMD {new Date().getFullYear()}
          </p>
        </div>
      </footer>
  )
}