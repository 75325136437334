import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import video from "../../images/RediMDBannerVid.mp4"
//Importing Modal
import Poster from "../../images/slide1.png";

//text rotator
import ReactTextRotator from "react-text-rotator";

const content = [
  {
    text: "Available 24/7 - No Traveling or Waiting",
    className: "classA",
    animation: "zoom",
  },
  {
    text: "Convenient and Affordable Healthcare",
    className: "classB",
    animation: "zoom",
  },
  {
    text: "We Accept Most Major Insurance",
    className: "classB",
    animation: "zoom",
  },
  {
    text: "Lower Medical Cost",
    className: "classB",
    animation: "zoom",
  },
];

const TextRotator = () => (
  <ReactTextRotator content={content} time={3000} startDelay={250} transitionTime={100}/>
);

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.closeModal.bind(this)
    this.callModal.bind(this);
  }

  async componentDidMount() {
    let videoEl = document.getElementById("video");    
    videoEl.Poster = {Poster};
    videoEl.play();
  }

  userPrompt = "Click to start video";

  callModal = () => {
    this.setState({ isOpen: true })
  };

  closeModal = () => {
    this.setState({ isOpen: false })
  };

  // if autoplay is disabled, clicking on the video will start the video
  handleVideoClick = (e) => {
    let videoEl = document.getElementById("video");
    videoEl.play();
    this.userPrompt = "";
  }

  handlePlayStarted = (e) => {
    this.userPrompt = "";
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section section-lg bg-primary home-header"
          id="home"
        >
          <video
              id="video"
              className="d-none d-md-block"
              onClick={this.handleVideoClick}
              onPlay={this.handlePlayStarted}
              style={{
                objectFit : "cover",
                position: "absolute",
                top: "0",
                // left: "0",
                width: "100%",
                maxHeight: "100%",
                minHeight: "100%"
              }}
               playsInline
               autoPlay
               muted
               loop
               >
              <source
                className="h-100"
                  src={video}
                  type="video/mp4"
                  />
            </video>
          <div className="bg-overlay h-100" 
            title={this.userPrompt}
            onClick={this.handleVideoClick}> 
          </div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row>
                  <Col
                    lg={{ size: 8, offset: 2 }}
                    className="text-white text-center"
                    onClick={this.handleVideoClick}
                  >
                    <h1 className="home-title text-rotate">
                      <TextRotator />
                    </h1>
                    <p className="pt-3 home-desc ">
                      Leading Telemedicine since 2008. Come see why millions of patients have this benefit and 95% of school districts in Texas use RediMD.
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
