import React from "react";
import {useState} from "react";
import {Nav, Navbar, NavbarToggler, NavbarBrand, Collapse, NavItem,  NavLink } from "reactstrap";
import RediMDLogo from "./RediMDLogo";

export default function TrsNavBar() {
  const [isOpen, setIsOpen] = useState(false);
  
 
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return(
    <>
      <Navbar color="light" dark expand="md" className="d-none d-md-block">
        <NavbarToggler onClick={toggle} />
        <NavbarBrand className="align-self-start" href="/#">
          <RediMDLogo />
        </NavbarBrand>
        <Collapse isOpen={isOpen} navbar id="collapsingNavbar">
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink
                className="text-black mx-2 mt-2"
                href="tel:855-942-4900"
              >Call us: 855-942-4900
              </NavLink>
            </NavItem>
            <NavItem>              
                <a
                    className="btn btn-outline-info my-2 mr-2 "
                    style={{marginRight:"2em"}}
                    type="button"
                    href={`${process.env.REACT_APP_FQDN}/assets/redimd_getting_started.pdf`}
                    >
                    How to Register
                </a>
            </NavItem>
            <NavItem>              
                <a
                    className="btn bg-primary text-white my-2 mr-2 "
                    style={{marginRight:"2em"}}
                    type="button"
                    target="_blank"
                    href={`${process.env.REACT_APP_FQDN}/auth/login`}
                    >
                    Patient login
                </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <div className="d-flex d-md-none flex-row d-flex justify-content-between bd-highlight p-1">
        <div className="align-self-start">
          <a href="/#" >
            <RediMDLogo />
          </a>
        </div>
        <div className="align-self-end">
          <a
              className="btn btn-outline-info my-2 mr-2 "
              style={{marginRight:"2em"}}
              type="button"
              href={`${process.env.REACT_APP_FQDN}/assets/redimd_getting_started.pdf`}
              >
              How to Register
          </a>          
          <a
            className="btn bg-primary text-white  "
            style={{marginRight:"1em"}}
            type="button"
            href={`${process.env.REACT_APP_FQDN}/auth/login`}
            >
            Patient login
          </a>
        </div>
      </div>        
    </>
  )
}