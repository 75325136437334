import React, { useState, useReducer } from "react";
import { format } from "date-fns";
import { Container, Row, Col } from "reactstrap";
import SectionTitle from "../common/section-title";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value
  }
}

const StatusDialog = styled.div`
    position:fixed;
    z-index: 5000;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: #000000c0;
  `;

function Contact(props) {
  const [formData, setFormData] = useReducer(formReducer,{});
  const [submitting, setSubmitting] = useState(false);
  const [dialog, setDialog] = useState({msgActive: false, 
                                        msgType:"none", 
                                        msgText:""});

  const [dialogStatus, setDialogStatus] = useState(false);
  
  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  }

  const handleDismiss = (event) => {
    setFormData({});
    document.forms[0].reset();
    setDialogStatus(false);
    setDialog({msgActive: false, 
      msgType:"none", 
      msgText:""
    });
  }
  
  const sendEmail = (JSONpacket) => {
    // old lambda SES API URL and Key
    // "https://x9beos0tm4.execute-api.us-west-2.amazonaws.com/stage/redimd-sendmail-from-web",
    // "x-Api-Key": 'Q6QdsTbOgG1bjeRxJahNi8TkyoDPdiR9o8J7U539'
    axios.post(
      process.env.REACT_APP_FQDN+"/api/send_mail_from_website",
      JSONpacket,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
        ).then((response) => {
          setDialogStatus(true);
          setDialog({msgActive: true, 
            msgType: "INFO", 
            msgText: "Your email has been sent. "+response.statusText
          });
        }
        ).catch((error) => {
          console.log(error);
          setDialogStatus(true);
          setDialog({msgActive: true, 
            msgType: "ERROR", 
            msgText: error.code+": "+error.message});
          
        }
        ).finally(() => {
          setSubmitting(false);
        });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
 
    setTimeout(() => {
      setSubmitting(false);
    }, 10000);

    const now = format(new Date(), "Pp");

    let body = '<h2>RediMD website contact message</h2>';
      body += '<strong>Sent on ' + now + '</strong>';
      body += '<br>';
      body += '<label style="font-weight:700;">Name:</label><br>';
      body += formData.name + '<br>';
      body += '<label style="font-weight:700;">Email:</label><br>';
      body += '<a href="mailto:' + formData.email + '">' + formData.email + '</a><br>';
      body += '<label style="font-weight:700;">Phone: </label><br>';
      body += formData.phone + '<br>';
      body += '<label style="font-weight:700;">Message:</label><br>';
      body += formData.comments + '</br>';

    sendEmail({
                "name"  : formData.name,
                "phone" : formData.phone,
                "from"  : formData.email,
                "body"  : body,
                "subject": formData.subject,
              });  
  }      
  
  return (
    <React.Fragment>
        {submitting &&
          <StatusDialog>
            <div 
              className="mx-auto text-center text-white" 
              style={
                {
                  top:"40%",
                  width: "inherit",
                  position:"absolute",
                  marginLeft:"auto",
                  marginRight:"auto",
                  textAlign: "center",
                  fontSize: "42px"
                }
              }>
              <FontAwesomeIcon icon={faSpinner} spin /><br/>Sending Email...
            </div>
          </StatusDialog>
        }
      {dialogStatus && 
        <div className="modal fade show" style={{display:"block", top:"30%"}} tabIndex="-1" data-bs-backdrop="static" >
          <div className="modal-dialog .modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">Email result</h2>
                <button type="button" className="btn-close" onClick={handleDismiss} data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>{dialog.msgText}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={handleDismiss} data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      }

      <section className="section " id="contact">
        <Container>
          {/* section title */}
          <SectionTitle
            title="GET IN TOUCH"
            desc="Didn't find what you're looking for on the FAQs page? Did you ask Amy?  Here's where to ask our staff website or administrative questions.  Email is not secure! Please do not send private medical information. ¡El correo electrónico no es seguro! Por favor, no envíe información médica privada."
          />

          <Row>
            <Col lg={4}>
              <div className="mt-4 pt-4">
                <p>
                  <span className="h5">Healthcare Services:</span>
                  <span className="text-muted d-block">
                    Providers available 24 hours a day
                  </span>
                </p>
                <p>
                  <span className="h5">Website Support:</span>
                  <span className="text-muted d-block">
                    Monday - Saturday 8:00am to 6:00pm central
                  </span>
                </p>
                <p>
                  <span className="h5">Address:</span>
                  <span className="text-muted d-block">
                    9337 B Katy Freeway <br/>
                    Suite #131 <br/>
                    Houston, TX 77024
                  </span>
                </p>
                <p>
                  <span className="h5">Call us today:</span><br/>
                  <a 
                    className="text-muted" 
                    href="tel:+18669892873"
                  >
                    866-989-2873
                  </a><br/>
                  <a 
                    className="text-muted" 
                    href="tel:+18669892873"
                  >
                    866-989-CURE
                  </a><br/>
                </p>
              </div>
            </Col>
            <Col lg={8}>
              <div className="custom-form mt-4 pt-4">
                <form name="myForm" onSubmit={handleSubmit}>
                  <p id="error-msg"></p>
                  <div id="simple-msg"></div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mt-2">
                        <input 
                          id="name" 
                          name="name" 
                          type="text" 
                          className="form-control"
                          placeholder="Your name"
                          onChange={handleChange} 
                          required
                          />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-2">
                        <input 
                          id="email" 
                          name="email" 
                          type="email" 
                          className="form-control"
                          placeholder="Your email" 
                          onChange={handleChange} 
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mt-2">
                        <input 
                          id="phone" 
                          type="tel"
                          pattern="/^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$/"
                          title="999-999-9999" 
                          className="form-control" 
                          name="phone"
                          placeholder="Phone number (optional) 999-999-9999" 
                          onChange={handleChange} 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mt-2">
                        <input 
                          id="subject" 
                          type="text" 
                          className="form-control" 
                          name="subject"
                          placeholder="Your Subject.." 
                          required
                          onChange={handleChange} 
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mt-2">
                        <textarea 
                          id="comments" 
                          name="comments" 
                          rows="4" 
                          className="form-control"
                          placeholder="Your message..."
                          required
                          onChange={handleChange} 
                          >
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 text-end">
                      <button 
                        id="submit" 
                        className="submitBnt btn btn-primary"
                        type="submit" 
                        name="send" 
                        //onSubmit={handleSubmit}
                        // onClick={handleSubmit}
                        >
                          Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Contact;
