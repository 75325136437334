import React, { Component } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";

import logoImage from "../../images/RediMD_Logo_colorized.png";

const defaultNavItems =  [
  { id: 1 , idnm : "/#home", navheading: "Home" },
  { id: 2 , idnm : `${process.env.REACT_APP_FQDN}/fit4duty`, navheading: "Fit for Duty" },
  { id: 3 , idnm : `${process.env.REACT_APP_FQDN}/fastchat`, navheading: "FastChat" },
  { id: 4 , idnm : "/faqs", navheading: "FAQs" },
  { id: 4 , idnm : "/amy", navheading: "Ask Amy" },
  { id: 5 , idnm : "/contact", navheading: "Contact" }
];

var navItems = [];

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      navClass : ""
    };
    navItems = (this.props.navItems === undefined) ? [...defaultNavItems] : [...this.props.navItems];
  }


  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  componentDidMount() {
    
  }

  componentWillUnmount() {
  }

  handleLoginClick = (e) => {
    window.location = `${process.env.REACT_APP_FQDN}/auth/login`;
  }

  handleHelpClick = (e) => {
    window.open(`${process.env.REACT_APP_FQDN}/assets/redimd_getting_started.pdf`, '_blank', 'noopener,noreferrer');
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      this.setState({ navClass : "is-sticky"});
    } else {
      this.setState({ navClass : ""});
    }
  }

  render() {
    return (
      <React.Fragment>
          <div id="navbar" className={this.state.navClass}>
            <nav
              className="navbar navbar-expand-md navbar-dark bg-dark fixed-top sticky"
            >
              <Container>
                <NavbarBrand className={`logo text-uppercase ${(this.state.navClass )? "bg-blend-mode: multiply":""}`} href="/">
                  <img src={logoImage} alt="RediMD logo" style={{maxHeight:"50px"}}/>
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle}>
                  <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse
                  id="navbarCollapse"
                  isOpen={this.state.isOpenMenu}
                  navbar
                >
                    <Nav navbar 
                      style={{zIndex:'100'}} 
                      className="navbar-center" 
                      id="mySidenav">
                      {navItems.map((item, key) => (
                        <NavItem
                          key={key}
                          className={item.idnm === window.location.pathname ? "active" : ""}
                        >
                          <NavLink href={item.idnm}
                            className="text-nowrap">
                            {item.navheading}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <div className="nav-button ms-auto">
                      <Nav navbar className="navbar-end">
                        <li>
                          <Button
                            color="none"
                            type="button"
                            className="btn btn-outline-warning navbar-btn"
                            onClick={this.handleHelpClick}
                          >
                            How to Register
                          </Button>
                        </li>
                        <li>&nbsp;&nbsp;&nbsp;</li>
                        <li>
                          <Button
                            color="none"
                            type="button"
                            className="btn btn-primary navbar-btn"
                            onClick={this.handleLoginClick}
                          >
                            Login/Sign up
                          </Button>
                        </li>
                      </Nav>
                    </div>
                  {/* </ScrollspyNav> */}
                </Collapse>
              </Container>
            </nav>
            </div>
      </React.Fragment>
    );
  }
}

export default NavbarPage;
