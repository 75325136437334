import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import backgroundImage from "../../images/slide4.png";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section section-lg"
          id="home"
          style={{
            overflow : "hidden",
            height : window.innerHeight * .50 + "px",
            backgroundImage : `url(${backgroundImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no",
            backgroundSize: "cover",
          }}
        >
          <div className="bg-overlay h-100"> </div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row>
                  <Col
                    lg={{ size: 8, offset: 2 }}
                    className="text-white text-center"
                  >
                    <h1 className="home-title text-rotate">
                      HIPAA Policy Statement
                    </h1>
                    <p 
                      className="pt-3"
                      style={{
                        fontWeight: 100,
                        fontSize: "24px",
                        lineHeight: "36px"
                      }}
                      >
                       This notice describes how medical information about you may be used and disclosed and how you can get access to this information. Please review it carefully. If you have any questions about this Notice please <a href="/contact">contact us</a>. 
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
        <section className="container text-muted">
          <Row className="mt-4">
            <Col>
              <p>
                This <strong>Notice of Privacy Practices</strong> describes how we may use and disclose your protected health information to carry out treatment, payment or health care operations and for other purposes that are permitted or required by law. It also describes your rights to access and control your protected health information. “Protected health information” is information about you, including demographic information, that may identify you and that relates to your past, present or future physical or mental health or condition and related health care services.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="sample-text">
                  We are required to abide by the terms of this Notice of Privacy Practices. We may change the terms of our notice, at any time. The new notice will be effective for all protected health information that we maintain at that time. Upon your request, we will provide you with any revised Notice of Privacy Practices by [if applicable, accessing our website (www.redimd.com)], calling the office and requesting that a revised copy be sent to you in the mail or asking for one at the time of your next appointment.
              </p>
              <p></p>
          <h3>
              Understanding Your Health Record/Information
          </h3>
          <p className="sample-text">
              Each time you visit a healthcare provider, a record of your visit is made. Typically, this record contains your symptoms, examination and test results, diagnoses, treatment, and a plan for future care or treatment. This information, often referred to as your health or medical record, serves as a:
          </p>
          <ul>
            <li>basis for planning your care and treatment
            </li>
            <li>means of communication among the many health professionals who contribute to your care
            </li>
            <li>legal document describing the care you received
            </li>
            <li>means by which you or a third-party payer can verify that services billed were actually provided a tool in educating heath professionals
            </li>
            <li>source of data for medical research
            </li>
            <li>source of information for public health officials charged with improving the health of the nation
            </li>
            <li>source of data for facility planning and marketing
            </li>
            <li>a tool with which we can assess and continually work to improve the care we render and the outcomes we achieve
            </li>
          </ul>
            <p></p>
            <p className="sample-text">
                Understanding what is in your record and how your health information is used helps you to:
                </p><ul>
                    <li>ensure its accuracy
                    </li>
                    <li>better understand who, what, when, where, and why others may access your health information
                    </li>
                    <li>make more informed decisions when authorizing disclosure to others
                    </li>
                </ul>
            <p></p>
            <h3>Your Health Information Rights</h3>
            <p className="sample-text">
                Although your health record is the physical property of the healthcare practitioner or facility that compiled it, the information belongs to you. You have the right to:
            </p>
            <ul>
                <li>request a restriction on certain uses and disclosures of your information
                </li>
                <li>obtain a paper copy of the notice of information practices upon request
                </li>
                <li>inspect and obtain a copy of your health record
                </li>
                <li>amend your health record
                </li>
                <li>obtain an accounting of disclosures of your health information
                </li>
                <li>request communications of your health information by alternative means or at alternative
                </li>
                <li>locations revoke your authorization to use or disclose health information except to the extent that action has already been taken
                </li>
            </ul>
            <p></p>
            <h3>Our Responsibilities</h3>
            <p className="sample-text">
                This organization is required to:
            </p>
            <ul>
                <li>maintain the privacy of your health information
                    </li>
                <li>provide you with a notice as to our legal duties and privacy practices with respect to
                    </li>
                <li>information we collect and maintain about you
                    </li>
                <li>abide by the terms of this notice
                    </li>
                <li>notify you if we are unable to agree to a requested restriction
                    </li>
                <li>accommodate reasonable requests you may have to communicate health information by alternative means or at alternative locations.
                    </li>
            </ul>
            <p></p>
            <p className="sample-text">
                We will not use or disclose your health information without your authorization, except as described in this notice.
                If you believe your privacy rights have been violated, you can file a complaint with our Privacy Contact or with the Secretary of Health and Human Services. There will be no retaliation for filing a complaint. You may <a href="/contact"> email</a> or call our toll free number for further information about the complaint process.
            </p>
            <p></p>
            <h3>
                    Examples of Disclosures
            </h3>
            <h4>For Treatment, Payment and Health Operations</h4>
            <ul>
                <li>
                    We will use your health information for treatment.
                    <br/>
                    For example: Information obtained by a therapist or other member of your healthcare team will be recorded in your record and used to determine the course of treatment that should work best for you. We may provide your physician or a subsequent healthcare provider with copies of various reports that should assist him or her in treating you.
                </li>
                <li>
                    We will use your health information for payment.
                    <br/>
                    For example: A bill may be sent to you or a third-party payer. The information on or accompanying the bill may include information that identifies you, as well as your diagnosis, procedures, and supplies used.
                </li>
                <li>
                    We will use your health information for regular health operations.
                    <br/>
                    For example: Members of our quality improvement team may use information in your health record to assess the care and outcomes in your case and others like it. This information will then be used in an effort to continually improve the quality and effectiveness of the healthcare and service we provide.
                </li>
                <li>
                    Business associates: There are some services provided in our organization through contacts with business associates. 
                    <br/>
                    Examples include our billing service and a copy service we use when making copies of your health record. When these services are contracted, we may disclose your health information to our business associate so that they can perform the job we’ve asked them to do and bill you or your third-party payer for services rendered. To protect your health information, however, we require the business associate to appropriately safeguard your information.
                </li>
                <li>
                    Notification: We may use or disclose information to notify or assist in notifying a family member, personal representative, or another person responsible for your care, your location, and general condition.
                </li>
                <li>
                    Communication with family: Health professionals, using their best judgment, may disclose to a family member, other relative, close personal friend or any other person you identify, health information relevant to that person’s involvement in your care or payment related to your care.
                </li>
                <li>
                    Research: We may disclose information to researchers when their research has been approved by an institutional review board that has reviewed the research proposal and established protocols to ensure the privacy of your health information.
                </li>
                <li>
                        Funeral directors: We may disclose health information to funeral directors consistent with applicable law to carry out their duties.
                </li>
                <li>
                    Organ procurement organizations: Consistent with applicable law, we may disclose health information to organ procurement organizations or other entities engaged in the procurement, banking, or transplantation of organs for the purpose of tissue donation and transplant.
                </li>
                <li>
                    Marketing: We may contact you to provide appointment reminders or information about treatment alternatives or other health-related benefits and services that may be of interest to you.
                </li>
                <li>
                    Fund raising: We may contact you as part of a fund-raising effort.
                </li>
                <li>
                    Food and Drug Administration (FDA): We may disclose to the FDA health information relative to adverse events with respect to food, supplements, product and product defects, or post marketing surveillance information to enable product recalls, repairs, or replacement.
                </li>
                <li>
                    Workers compensation: We may disclose health information to the extent authorized by and to the extent necessary to comply with laws relating to workers compensation or other similar programs established by law.
                </li>
                <li>
                    Public health: As required by law, we may disclose your health information to public health or legal authorities charged with preventing or controlling disease, injury, or disability.
                </li>
                <li>
                    Correctional institution: Should you be an inmate of a correctional institution, we may disclose to the institution or agents thereof health information necessary for your health and the health and safety of other individuals.
                </li>
                <li>
                    Law enforcement: We may disclose health information for law enforcement purposes as required by law or in response to a valid subpoena.
                </li>
                <li>
                    Federal law makes provision for your health information to be released to an appropriate health oversight agency, public health authority or attorney, provided that a work force member or business associate believes in good faith that we have engaged in unlawful conduct or have otherwise violated professional or clinical standards and are potentially endangering one or more patients, workers or the public.
                </li>
          </ul>
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
