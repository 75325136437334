import React from "react";
import routes from "./routes";
import { Route, Routes } from "react-router-dom";
import ScrollTop from "components/ScrollTop/ScrollTop";
import "./css/bootstrap.min.css";
import "./css/style.css";
import "./css/simpletextrotator.css";

function App() {
  return (
    <React.Fragment>
      <ScrollTop>
      <Routes>
        {routes.map((route, idx) => (
          <Route path={route.path} element={route.component} key={idx} />
        ))}
      </Routes>
      </ScrollTop>
    </React.Fragment>
  );
}

export default App;
