import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Footer from "../../components/Footer/footer";

class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage navClass={this.state.navClass} />

        {/* section */}
        <Section />

        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default PageNotFound;
