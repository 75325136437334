import React, { useState, useEffect } from 'react';
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import ChatBot   from "../../components/Chatbot/chatbot";
import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

function RediMDAmy (){
    const [cookies, setCookie] = useCookies(["acceptTerms"]);
    const [dialogStatus, setDialogStatus] = useState(true);

    const Blanket = styled.div`
        position: fixed;
        top: 0;
        left:0;
        width:100%;
        height: 100vh;
        background-color: #000000c8;
        z-index:1000;
    `;
    useEffect(() => {
        window.scrollTo(0,0);
        setDialogStatus(cookies.acceptTerms !== "true");
    }, [cookies]);

    function saveCookie(cookieValue) {
        var tomorrow = new Date().getTime() + 60 * 60 * 24 * 1000;
    
        setCookie("acceptTerms", cookieValue, {
          path: "/",
          expire: tomorrow,
          maxAge: 3600*24*30,
         });
    
        return cookieValue;
      }

    const handleAccept = (event) => {
        saveCookie(true);
        setDialogStatus(false);
      }
    
    const handleDecline = (event) => {
        saveCookie(false);
        window.location.href="/#";
      }
    
    return (
        <React.Fragment>
            {dialogStatus && 
            <>
                <Blanket id="blanket"/>
                <div className="modal fade show" style={{display:"block"}} tabIndex="-1" data-bs-backdrop="static" >
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-fullscreen-md-down">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h2 className="modal-title">Disclaimer</h2>
                        <button type="button" className="btn-close" onClick={handleDecline} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                            “Ask Amy” is an artificial intelligence (AI) feature and does not make medical diagnoses.  Seek the advice of a qualified medical professional if you are concerned about your health.  In case of emergency, you should dial 911 or visit your closest emergency room.
                            </p>
                            <p>
                            Information provided by “Ask Amy” is not intended to be a substitute for professional medical advice, diagnosis or treatment.  “Ask Amy” provides information to help guide your decision making based on readily available information about symptoms.  “Ask Amy” responses are generated based on patterns learned from a large dataset.  While efforts have been made to ensure accuracy, the responses may not always be based on information that is up-to-date, complete or applicable to your specific medical condition.  
                            </p>
                            <p>
                            We do not guarantee the accuracy, reliability, or timeliness of any information provided by “Ask Amy”.  We are not responsible or liable for any consequences resulting from the use of or reliance on “Ask Amy” responses.  You should not disregard a qualified medical professional’s advice - or delay or cancel a medical appointment - because you are relying on responses from “Ask Amy.”   
                            </p>
                            <p>
                            By using “Ask Amy”, you acknowledge that you have read and understood this disclaimer and agree to use the information provided at your own discretion and risk. 
                            </p>
                           </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={handleDecline} data-bs-dismiss="modal">Decline</button>
                        <button type="button" className="btn btn-primary" onClick={handleAccept} data-bs-dismiss="modal">Accept</button>
                        </div>
                    </div>
                    </div>
                </div>   
            </>
            }

            {/* Importing Navbar */}
            {/* <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} /> */}
            
            <NavbarPage/>

            {/* section */}
            <Section/>


            {/* AI Amy */}
            <ChatBot />

            {/* social */}
            <Social />

            {/* footer */}
            <Footer/>
        </React.Fragment>
    );
}

export default RediMDAmy;