import React from "react";
import TrsNavBar from "components/Trs/TrsNavBar";
import WCHeader from "components/WCHeader/WCHeader";
import HeaderImage from "../../images/construction-worker.jpg"
import Content from "./Content";
import TrsFooter from "components/Trs/TrsFooter";
import TrsCookies from "components/Trs/TrsCookies";
import "../../css/trsactivecare.scss";

export default function RediMDWC() {
  document.title = "RediMD - Workers Compensation"
  return (
    <>
      {/* Navigation */}
      <TrsNavBar />

      {/* Header - set the background image for the header in the line below */}
      <WCHeader bgImage={HeaderImage} />

      {/* Content section */}
      <Content />

      {/* Footer */}
      <TrsFooter />

      {/* GDPR cookie message Start */}
      <TrsCookies />
    </>
  );
}
