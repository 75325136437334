import React from "react";
import RediMDLogoImage from "../../images/RediMD_Logo_Black.png";
import Styled from "styled-components";


export default function RediMDLogo () {
  const Logo = Styled.img`
    height: 50px;
  `;
    return (
      <React.Fragment>
        <div className="logocontainer">
          <Logo
            src={RediMDLogoImage}
            alt="rediMDLogo"
          />
        </div>
      </React.Fragment>
  )
}