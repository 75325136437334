import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
//import ModalSection from "../../components/common/ModalSection";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div id="findit" className="section home-half">
          <div className="bg-overlay"></div>
            <Container>
              <Row className="d-flex justify-content-center aligh-items-center">
                <Col lg={8} className="text-white text-center">
                  <h1 className="home-title">
                    Oops! We can't find the page you're looking for.
                  </h1>
                  <p className="pt-3 home-desc">
                    Choose a link from the menu above
                  </p>
                </Col>
              </Row>
            </Container>
          <div className="bg-pattern-effect">
            <img src="assets/images/bg-pattern.png" alt="RediMD" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Section;
