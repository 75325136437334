import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

class Social extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="contact-social bg-light">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} lg={5}>
                <ul className="list-inline social mt-4">
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-facebook"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-twitter"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-instagram"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-linkedin"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#" className="social-icon">
                      <i className="mdi mdi-google-plus"></i>
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col xs={12} lg={3} className="mt-4">
                <Link to="tel:(866)989-2873">
                  <p className="contact-title  d-flex align-items-start  text-muted">
                    <i className="pe-7s-call"></i> &nbsp;(866)989-2873
                  </p>
                </Link>
              </Col>
              <Col xs={12} lg={4} className="mt-4 text-end">
                <Link to="mailto:redimdsupport@redimd.com">
                  <p className="contact-title d-flex align-items-end text-muted">
                    <i className="pe-7s-mail-open me-2"></i>
                    redimdsupport@redimd.com
                  </p>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Social;
