import React, { Component } from "react";
import { AppStoreBadge, PlayStoreBadge } from '../Badges/Badges';

class Apps extends Component {
    render() {
      return (
        <div style={{ width: "496px", 
                    height: "120px",
                    margin: "auto",
                    padding: "0",
                    paddingLeft: ".3in" }}>
            <PlayStoreBadge appId="com.redimd.clients.twa" />
            <AppStoreBadge appId="id6642666471" />
        </div>
      );
    }
}
export default Apps;