import React, { Component } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Contact from "../../components/Contact/Contact";
import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";

class RediMDContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navClass : ""
        };
    }

    render() {
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                {/* <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} /> */}
                <NavbarPage navClass={this.state.navClass} />

                {/* section */}
                <Section/>

                {/* contact */}
                <Contact/>

                {/* social */}
                <Social />

                {/* footer */}
                <Footer/>
            </React.Fragment>
        );
    }
}

export default RediMDContact;