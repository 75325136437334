import React from "react";

import Index1 from "./pages/Index1/Index1";
import Index2 from "./pages/Index2/Index2";
import Index3 from "./pages/Index3/Index3";
import Index4 from "./pages/Index4/Index4";
import Index5 from "./pages/Index5/Index5";
import Index6 from "./pages/Index6/Index6";
import Index7 from "./pages/Index7/Index7";
import Index8 from "./pages/Index8/Index8";
import Index9 from "./pages/Index9/Index9";
import Guardian from "./components/Guardian/Main";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import RediMDContact from "./pages/RediMDContact/RediMDContact";
import RediMDAmy from "./pages/RediMDAmy/RediMDAmy";
import RediMDTnc from "./pages/RediMDTnc/RediMDTnc";
import RediMDHipaa from "./pages/RediMDHipaa/RediMDHipaa";
import RediMDPrivacy from "./pages/RediMDprivacy/RediMDPrivacy";
import RediMDFaqs from "./pages/RediMDFaqs/RediMDFaqs";
import RediMDMainLanding from "./pages/RediMDMainLanding/RediMDMainLanding";
import TrsActiveCare from "./pages/TrsActive/TrsActive";
import TrsCareStandard from "./pages/TrsCareStandard/TrsCareStandard";
import RediMDWC from "pages/RediMDWorkersComp/RediMDWC";

//Auth Pages
// import Signup from "./pages/Auth/Signup";
// import Login from "./pages/Auth/Login";
// import PasswordForget from "./pages/Auth/PasswordForget";

const routes = [
  // { path: "/password-forget", component: <PasswordForget /> },
  // { path: "/login", component: <Login /> },
  // { path: "/sign-up", component: <Signup /> },
  { path: "/9", component: <Index9 /> },
  { path: "/8", component: <Index8 /> },
  { path: "/7", component: <Index7 /> },
  { path: "/6", component: <Index6 /> },
  { path: "/5", component: <Index5 /> },
  { path: "/4", component: <Index4 /> },
  { path: "/3", component: <Index3 /> },
  { path: "/2", component: <Index2 /> },
  { path: "/1", component: <Index1 /> },
  { path: "/trsactivecare", component: <TrsActiveCare /> },
  { path: "/trscarestandard", component: <TrsCareStandard /> },
  { path: "/wc", component: <RediMDWC /> },
  { path: "/w-c", component: <RediMDWC /> },
  { path: "/w_c", component: <RediMDWC /> },
  { path: "/amy", component: <RediMDAmy /> },
  { path: "/contact", component: <RediMDContact /> },
  { path: "/hipaa", component: <RediMDHipaa /> },
  { path: "/privacy", component: <RediMDPrivacy /> },
  { path: "/t-n-c", component: <RediMDTnc /> },
  { path: "/faqs", component: <RediMDFaqs /> },
  { path: "/guardian", component: <Guardian /> },
  { path: "/", component: <RediMDMainLanding /> },
  { path: "/*", component: <PageNotFound /> },
];

export default routes;
