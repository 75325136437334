import React from "react";
import "../../css/cookiealert.css"; 
import { useCookies } from 'react-cookie';

export default function TrsCookies() {
    const [cookies, setCookie] = useCookies(["cookieAsk"]);

    function saveCookie(cookieValue) {
      var oneyear = new Date().getTime() + 60 * 60 * 24 * 365 * 1000;
  
      setCookie("cookieAsk", cookieValue, {
        path: "/",
        expire: oneyear,
       });
  
      return cookieValue;
    }
    
    const handleAcceptBtn  = (event) => {
        saveCookie("yes");
    }

  return (
    <React.Fragment>
        {cookies.cookieAsk !== "yes" && 
            <div className="cookiealert text-center">
            <h2 className="inline-block"><b>Do you like cookies?</b> 🍪</h2>
            <h4 className="inline-block"> We use cookies to ensure you get the
            best experience on our website.</h4>
            <a href="http://cookiesandyou.com/" target="_blank" rel="noreferrer">
                Learn more
            </a>
            <button
                type="button"
                className="btn btn-primary btn-sm acceptcookies"
                onClick={handleAcceptBtn}
                aria-label="Close"
            >
                I agree
            </button>
            </div>
        }
    </React.Fragment>
 )
}