import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import Styled from "styled-components";


export default function WCHeader(props) {
  const HeaderImg = Styled.header`
    background-image: url(${props.bgImage})
  `;

  const Container = Styled.div`
    padding: 2em;
    radius: 5px;
  `;

  return (
    <HeaderImg
      className="py-5 bg-image-full"
    >
        <Container className="Container p-3 col-sm-8 col-md-6 col-xl-4 d-flex flex-column justify-content-start">
          <div className="p-2 mb-4 w-100"
            style={{
              backgroundColor: "#0D6EFD95",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px #ccc"
            }}>
            <h1 className="text-center white">
              <span className="">Leading Telemedicine</span>
            </h1>
            <h1 className="text-center white">
              <span className="">In Workers' Comp</span>
            </h1>
            <h1 className="text-center white">
              <span className="">For Two Decades</span>
            </h1>
          </div>
          <div className="d-flex flex-column p-3  mb-4 w-100" 
            style={{
              borderRadius: "5px", 
              boxShadow: "0px 0px 5px #ccc", 
              backgroundColor:"#FFFFFF95"
            }}>
            <h2 className="text-center blue">Convenient Affordable Healthcare</h2>
            <div className="mx-auto">
            <h3 className="bs-gray-400">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="blue"/>
              &nbsp;&nbsp;Lower medical costs
            </h3>
            <h3 className="bs-gray-400">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="blue"/>
              &nbsp;&nbsp;Available 24/7
            </h3>
            <h3 className="bs-gray-400">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="blue"/>
              &nbsp;&nbsp;No traveling or waiting
            </h3>
            <h3 className="bs-gray-400">
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="blue"/>
              &nbsp;&nbsp;98% Satisfaction rate
            </h3>
          </div>
          </div>
          <a
            className="btn btn-success btn-lg btn-block text-center my-2 p-3 "
            type="button"
            href="/contact"
          >
            Find out more…
          </a>  

        </Container>

    </HeaderImg>
)
}