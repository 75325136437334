import React from "react";
import TrsNavBar from "components/Trs/TrsNavBar";
import TrsHeader from "components/Trs/TrsHeader";
import HeaderImage from "../../images/trsheader.jpg"
import Content from "./Content";
import TrsFooter from "components/Trs/TrsFooter";
import TrsCookies from "components/Trs/TrsCookies";
import "../../css/trsactivecare.scss";

export default function TrsActiveCare() {
  document.title = "RediMD - TRS Active Care"
  return (
    <>
      {/* Navigation */}
      <TrsNavBar />

      {/* Header - set the background image for the header in the line below */}
      <TrsHeader bgImage={HeaderImage} />

      {/* Content section */}
      <Content />

      {/* Footer */}
      <TrsFooter />

      {/* GDPR cookie message Start */}
      <TrsCookies />
    </>
  );
}
