import React from "react";

export default function Content() {
  return(
    <React.Fragment>
      <section className="py-5">
        <div className="container">
          <h1>Welcome to RediMD </h1>
            <p className="lead text-justify">
                We are proud to be one of the largest providers of workers' compensation telemedicine services in the United States, with a focus on delivering safe and effective care to our domestic clients across a range of industries. Our expertise extends beyond telemedicine to encompass staffing, consulting, and return to work strategies, allowing us to provide comprehensive support to our clients. Our clients represent a diverse cross-section of private and public sector organizations, including large petrochemical and transportation companies, school districts, and county entities. We are confident in our ability to help your organization thrive, and we welcome any inquiries or questions about how we can support your specific needs.
            </p>
        </div>
      </section>
    </React.Fragment>          
  )
}