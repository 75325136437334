import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";

class RediMDHipaa extends Component {
    render() {
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                <NavbarPage />

                {/* section */}
                <Section/>
 
                {/* social */}
                <Social />

                {/* footer */}
                <Footer/>
            </React.Fragment>
        );
    }
}

export default RediMDHipaa;