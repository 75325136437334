import React, { Component } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Service from "../../components/Service/service";
import AboutUs from "../../components/AboutUs/AboutUs";
import Apps from "../../components/Apps/Apps";
// import WebsiteDesc from "../../components/WebsiteDesc/WebsiteDesc";
// import Pricing from "../../components/Pricing/pricing";
// import Team from '../../components/Team/Team';
import Process from "../../components/Process/Process";
// import Testimonials from "../../components/Testimonials/Testimonials"
import GetStart from "../../components/GetStart/GetStart"
// import Blog from "../../components/Blog/Blog"
import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";

class RediMDMainLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navClass : ""
        };
    }

    render() {
        return (
            <React.Fragment>
                
                {/* Importing Navbar */}
                {/* <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} /> */}
                <NavbarPage navClass={this.state.navClass} />

                {/* section */}
                <Section/>

                {/* section */}
                <Apps/>

                {/* services */}
                <Service />

                {/* about us */}
                <AboutUs/>
                
                {/* website description */}
                {/* <WebsiteDesc/> */}

                {/* pricing */}
                {/* <Pricing/> */}

                {/* team */}
                {/* <Team/> */}

                {/* process */}
                <Process/>

                {/* testimonial */}
                {/* <Testimonials/> */}

                {/* get started */}
                <GetStart/>

                {/* blog */}
                {/* <Blog/> */}

                {/* social */}
                <Social />

                {/* footer */}
                <Footer/>
            </React.Fragment>
        );
    }
}

export default RediMDMainLanding;