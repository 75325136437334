import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ProcessBox from "./ProcessBox";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processes: [
        {
          icon: "pe-7s-id",
          title: "Register or Sign in",
          desc: "To sign up, click 'Login/Sign up' above or 'Register For Free' button below. New patients require a registration code which can be obtained from your human resources department. If you work for a Texas school district, you can call us for your code. For uninsured or those who can't find the code, use REDIMD for your code. If your plan's copayment is less than our co-pay, the difference will be refunded.",
        },
        {
          icon: "pe-7s-clock",
          title: "Book an Appointment",
          desc: "Once registered, patients can login and book an appointment with a licensed medical professional. Simply click the big green button that says 'Make Appointment' and follow the instructions on your device to choose a healthcare provider at a convenient time for you. RediMD offers same-day and scheduled appointments for a variety of medical conditions.",
        },
        {
          icon: "pe-7s-phone",
          title: "Virtual Consultation",
          desc: "During the virtual consultation, patients can discuss their symptoms with the medical professional, who will provide a diagnosis and treatment plan which may include prescribing medicines, ordering bloodwork tests or imaging scans if needed, as well as referrals.",
        },
        {
          icon: "pe-7s-bandaid",
          title: "Prescription Delivery and Follow Up Care",
          desc: "After the virtual consultation, if a prescription is required, RediMD's licensed medical professional will send it directly to the patient's preferred pharmacy, digitally for pick-up or delivery. RediMD can also provide doctor’s notes for work and school.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light">
          <Container>
            {/* section title */}
            <SectionTitle
              title="HOW IT WORKS"
              desc="RediMD utilizes state-of-the-art telemedicine technology to provide patients with secure and confidential virtual care."
            />
            <Row>
              <Col lg={3} className="text-center process-left-icon-1">
                <i className="pe-7s-angle-right"></i>
              </Col>
              <Col lg={3} className="text-center process-left-icon-1">
                <i className="pe-7s-angle-right"></i>
              </Col>
              <Col lg={3} className="text-center process-left-icon-1">
                <i className="pe-7s-angle-right"></i>
              </Col>
              <Col lg={3} className="text-center">
              </Col>
            </Row>
            <Row className="mt-5">
              <ProcessBox processes={this.state.processes} />
              <div className="text-center mx-auto">
                <Link
                  to={process.env.REACT_APP_FQDN+"/user/register"}
                  className="btn btn-primary waves-light waves-effect mt-5"
                >
                  Register For Free <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
