import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";
//import Switcher from "./Switcher";

class Footer extends Component {
  state = {
    links: [
      {
        title: "RediMD",
        child: [
          { link: "/", title: "Home" },
          { link: "/#services", title: "About us" },
          { link: "/#amy", title: "AI Amy" },
        ],
      },
      {
        title: "Information",
        child: [
          { link: "/t-n-c", title: "Terms and conditions" },
          { link: "/privacy", title: "Privacy policy" },
          { link: "/hipaa", title: "HIPAA" },
        ],
      },
      {
        title: "Support",
        child: [
          { link: "/faqs", title: "FAQ" },
          { link: "/contact", title: "Contact us" },
          { link: "/amy", title: "Ask Amy" },
        ],
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              {this.state.links.map((fLink, key) => (
                <Col key={key} className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-2">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <a href={fLinkChild.link}>{fLinkChild.title}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </footer>
        {/* Render footer links */}
        <FooterLinks />

        {/* color switcher */}
      </React.Fragment>
    );
  }
}

export default Footer;
