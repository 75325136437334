import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import backgroundImage from "../../images/slide2.png";
class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section section-lg"
          id="home"
          style={{
            overflow : "hidden",
            height : window.innerHeight * .50 + "px",
            backgroundImage : `url(${backgroundImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no",
            backgroundSize: "cover",
          }}
        >
          <div className="bg-overlay h-100"> </div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row>
                  <Col
                    lg={{ size: 8, offset: 2 }}
                    className="text-white text-center"
                  >
                    <h1 className="home-title text-rotate">
                      Terms and Conditions
                    </h1>
                    <p 
                      className="pt-3"
                      style={{
                        fontWeight: 100,
                        fontSize: "24px",
                        lineHeight: "36px"
                      }}
                      >
                      When you register on our site, you agree to these terms and conditions of use.
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
        <section className="container mt-4 text-muted">
          <Row>
            <Col>
              <p>
                Please read these Terms of Use ("Terms", "Terms of Service") carefully before using the http://redimd.com website (the "Service") operated by Redimd, LLC ("us", "we", or "our"). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
                </p>
                <hr/>
                <h3>
                    Services
                </h3>                
                <p class="sample-text">
                    PATIENT UNDERSTANDING AS TO PRIMARY PRACTICE MEDICAL SERVICES BEING PROVIDED
                </p>
                <p class="sample-text">
                    I understand that in receiving medical treatment thru Redimd:
                </p>
                <ol>
                    <li class="sample-text">
                        Such treatment is only for minor medical issues such as common illnesses or minor injuries. Some examples
                        include the following: flu, sinus infections, bronchitis, conjunctivitis, hypertension, diabetes,
                        gastroenteritis, cellulitis, acne, alopecia, contact dermatitis, pharyngitis, contusions, strains,
                        epicondylitis, urinary tract infections, allergies, and other common ailments that anti-bodies,
                        anti-inflammatories and other non-narcotic prescriptions can cure. I recognize that receiving medical
                        treatment thru Redimd is not for treatment of major illnesses.
                    </li>
                    <li class="sample-text">I am required to be honest by providing accurate and complete information about my past and current
                        medical history and conditions. I recognize that Redimd is not affiliated or owned by my Employer. I
                        recognize that my Employer is not offering medical treatment. I recognize that receiving medical treatment
                        from a licensed doctor and/or nurse practitioner through Redimd is an alternative to receiving medical
                        treatment from a physician in medical facilities. I agree receiving medical treatment from Redimd is
                        voluntary and of my own free choice.
                    </li>
                </ol> 

                <p class="sample-text">
                    If you wish to contact a healthcare provider made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your legal name, billing address credit card number, expiration date and CVV number.  All payments are processed by PayPal.  We do not store or retain any payment method information.
                </p>
                <p></p>                        
                <h3>
                    Termination
                </h3>
                    
                <p class="sample-text">
                    We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                </p>
                
                <p class="sample-text">
                    All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, indemnity and limitations of liability.
                </p>
                <p></p>                        
                <h3>
                    Links To Other Web Sites
                </h3>
                
                <p class="sample-text">
                    Our Service may contain links to third-party web sites or services that are not owned or controlled by Redimd, LLC.</p>
                <p></p>
                <p class="sample-text">
                    Redimd has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that Redimd shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
                </p>
                <p></p>                        
                <h3>
                    Changes
                </h3>
                
                <p class="sample-text">
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                </p>
            </Col>
          </Row>
        </section>
      </React.Fragment>

    );
  }
}

export default Section;
