import React from "react";

export default function Content() {
  
  return(
      <><section>
      <div className="container">
        <div className="dropbox">
         <div className="title py-1 mt-0 mb-0">
            <h1 className="text-center blue">
              <span className="">
                Take Charge of Your Wellness from Anywhere, Anytime
              </span>
            </h1>
            <h2 className="text-center green">
              <span className="">
                Speak with a licensed healthcare provider in minutes
              </span>
            </h2>
          </div> 
          <div className="card-group">
            <div className="card green-bg">
              {/* <img src="..." class="card-img-top" alt="..."> */}
              <div className="card-body">
                <h1 className="card-title">Speak with a doctor</h1>
                <div className="card-text">
                  <form
                    action={`${process.env.REACT_APP_FQDN}/auth/authenticate`}
                    id="login"
                    autoComplete="off"
                    method="post"
                    acceptCharset="utf-8"
                    className="clients"
                  >
                    <input type="hidden" name="external" defaultValue={1} />
                    <input type="hidden" name="contactType" defaultValue="email" />
                    <div className="form-group">
                      <label className="white" htmlFor="user_name">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="user_nameprimary"
                        name="user_name"
                        autoComplete="email"
                        required />
                    </div>
                    <div className="form-group">
                      <label className="white" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="passwordprimary"
                        name="password"
                        autoComplete="current-password"
                        required />
                    </div>
                    <div className="form-group">
                      <a className="white clients" href="/auth/forgot_password">
                        I forgot my password
                      </a>
                    </div>
                    <button type="submit" className="btn btn-outline-light">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="card blue-bg">
              {/* <img src="..." class="card-img-top" alt="..."> */}
              <div className="card-body">
                <h1 className="card-title">Create an account</h1>
                <div className="card-text">
                  <form
                    action={`${process.env.REACT_APP_FQDN}/user/remoteregister`}
                    id="register"
                    autoComplete="on"
                    method="post"
                    acceptCharset="utf-8"
                    className="clients"
                  >
                    <input type="hidden" name="external" defaultValue={1} />
                    <input type="hidden" name="contactType" defaultValue="email" />
                    <div className="form-group">
                      <label className="white" htmlFor="user_name">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="user_name"
                        name="email"
                        autoComplete="email"
                        required="required"
                        aria-describedby="emailHelp" />
                      <small id="emailHelp" className="form-text white">
                        We'll never share your email with anyone else.
                      </small>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="white" htmlFor="password">
                            New password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            autoComplete="new-password"
                            required />
                          <small className="form-text white">
                            (at least 8 characters no spaces)
                          </small>
                        </div>
                        <div className="col">
                          <label className="white" htmlFor="password2">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="password2"
                            name="password2"
                            autoComplete="password"
                            required />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="white" htmlFor="first_name">
                        First name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        autoComplete="given-name"
                        required />
                    </div>
                    <div className="form-group">
                      <label className="white" htmlFor="last_name">
                        Last name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                        autoComplete="family-name"
                        required />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="white" htmlFor="dob">
                            Birthdate
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="dob"
                            name="dob"
                            autoComplete="bday"
                            required />
                        </div>
                        <div className="col">
                          <label className="white" htmlFor="code">
                            RediMD registration code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="code"
                            name="code"
                            required />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-outline-light">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-5">
      <div className="container">
        <h1>Welcome to RediMD </h1>
        <p className="lead">RediMD welcomes TRS-ActiveCare participants</p>
        <p>
          We are excited to partner with TRS to provide convenient quality patient
          care services to all the wonderful participants of TRS-ActiveCare.
          RediMD was <a href='#aboutus'>established in 2006</a> with the idea of
          providing telemedicine to patients to help them{" "}
          <em>
            <strong>"Get Well Sooner"</strong>
          </em>
          .
        </p>
        <p>
          Since our humble beginnings we have grown to provide care for many
          thousands of patients. We now see more than 100,000 patients annually,
          usually within just a few minutes. No more getting out of bed to drive
          to the doctor and sit in a waiting room just to get a prescription. Talk
          to a doctor on the phone or through video conference from your computer
          or mobile device from the comfort of your home.
        </p>
        <p>Thanks for joining us. We look forward to serving you.</p>
      </div>
    </section></>          
  )
}