import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
// import { Link } from "react-router-dom";
import aiamy from "../../images/ai_amy.gif";

class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="amy">
          <Container>
            <Row className="vertical-content">
              <Col lg={5}>
                <div className="features-box">
                  <h3>
                    Leading Telemedicine with Artificial Intelligence
                  </h3>
                  <p className="text-muted web-desc">
                    Our AI assistant Amy can provide accurate information on many medical and administrative topics. Just ask Amy like you would ask a human.<br/><i>PS. She also speaks many languages</i>
                  </p>
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className=""><b>Facts about illness and disease.</b><br/>What is diabetes?</li>
                    <li className="">
                      <b>Recommendation on diets to manage chronic illness.</b><br/>What is a recipe with chicken for diabetics?
                    </li>
                    <li className="">
                      <b>Explanation of medical terms.</b><br/> What is a latisimus dorsi?
                    </li>
                  </ul>
                  <a
                    href="/amy"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Try it <i className="mdi mdi-arrow-right"></i>
                  </a>
                </div>
              </Col>
              <Col lg={7}>
                <div className="features-img features-right text-end">
                  <img
                    src={aiamy}
                    alt="macbook"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
