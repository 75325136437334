import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import * as tf from "@tensorflow/tfjs";
import "../style.css";

const THRESHOLD = 0.6;
const DAMPENER = 15;

// 1st model
const CLASSESDIR = {
  1: {
    name: "0",
    id: 1,
  },
  2: {
    name: "1",
    id: 2,
  },
  3: {
    name: "2",
    id: 3,
  },
  4: {
    name: "3",
    id: 4,
  },
  5: {
    name: "4",
    id: 5,
  },
  6: {
    name: "5",
    id: 6,
  },
  7: {
    name: "6",
    id: 7,
  },
  8: {
    name: "7",
    id: 8,
  },
  9: {
    name: "8",
    id: 9,
  },
  10: {
    name: "9",
    id: 10,
  },
};

class BpMonitor extends React.Component {

  constructor(props) {
    super(props);
    this.capture = this.capture.bind(this);
    this.state = { 
        sys: "", 
        dia: "", 
        pulse: "", 
        isLoading: true, 
        boundingBoxes: "", 
        bufferedData: [], 
    };
  }
  
  videoRef = React.createRef();
  canvasRef = React.createRef();
  shutterBox = React.createRef();
  canvasBox = React.createRef();

  currentFrame = DAMPENER + 1;

  async componentWillUnmount() {
    document.body.style.backgroundColor = "white";
  } 

  async componentDidMount() {
    document.body.style.backgroundColor = "black";

    // moved here because it never executes in prototype
    tf.setBackend("webgl");

    // Loading Model
    try {
      // 1st model
      const modelURL = `${window.location.origin}/model/model.json`;
      console.log(modelURL);
      
      this.model = await tf.loadGraphModel(modelURL);

      //this.model = await tf.loadGraphModel("https://raw.githubusercontent.com/Think-Evolve-Consulting/TFJS-GuardianModel/master/models/web_model/model.json?token=GHSAT0AAAAAAB33V7MEYMSX6K7QRJJE3RDOY6Q7D5A");
      //this.model = await tf.loadGraphModel(process.env.REACT_APP_MODEL);
      // this.model = await tf.loadGraphModel(modelData);
    } catch (err) {
      console.error(err);
    }

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "environment",
          },
        })
        .then((stream) => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          
          return new Promise((resolve, reject) => {
            this.videoRef.current.onloadedmetadata = () => {
              // setup listen for resize event
              resolve();
            };
          });
        });

      const modelPromise = this.model;

      Promise.all([modelPromise, webCamPromise])
        .then((values) => {
          this.setState({ isLoading: false });
          this.listenForResizeEvent();
          this.detectFrame(this.videoRef.current, values[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    }    
  }

  windowResized = () => {
    // Sizing the canvas
    try {
      const canvas = this.canvasRef.current;
      const video = this.videoRef.current;
  
      canvas.width = video.clientWidth;
      canvas.height = video.clientHeight;

      let wh = this.canvasBox.current.clientWidth;
      let ht = this.canvasBox.current.clientHeight;

      // add pixels for non-scrolling shutterbox
      ht = ht + this.shutterBox.current.scrollHeight;

      // tell the parent about the resize
      window.parent.postMessage({
        "type":"resize", 
        "width": wh, 
        "height": ht
      }, "*");
      console.debug(`resize event w:${wh}, h:${ht}`);

    } catch (error) {}
  }

  listenForResizeEvent = () => { 
    this.windowResized();
    window.addEventListener("resize", this.windowResized);
  }

  detectFrame = (video, model) => {
    tf.engine().startScope();
    model.executeAsync(this.process_input(video)).then((predictions) => {
      this.showDetections(predictions, video);
      requestAnimationFrame(() => {
        this.detectFrame(video, model);
      });
      tf.engine().endScope();
    });
  };

  process_input(video_frame) {
    const tfimg = tf.browser.fromPixels(video_frame).toInt();
    const expandedimg = tfimg.transpose([0, 1, 2]).expandDims();
    return expandedimg;
  }

  buildDetectedObjects(scores, boxes, classes) {
    const detectionObjects = [];
    var video_frame = document.getElementById("frame");

    scores[0].forEach((score, i) => {
      if (score > THRESHOLD) {
        detectionObjects.push({
          class: classes[i],
          label: CLASSESDIR[classes[i]].name,
          score: score.toFixed(4),
          rect: {
            "left" : boxes[0][i][1] * video_frame.offsetWidth,
            "top": boxes[0][i][0] * video_frame.offsetHeight,
            "right": boxes[0][i][3] * video_frame.offsetWidth,
            "bottom": boxes[0][i][2] * video_frame.offsetHeight,
            "width": ( boxes[0][i][3] - boxes[0][i][1] ) * video_frame.offsetHeight,
            "height": ( boxes[0][i][2] - boxes[0][i][0] ) * video_frame.offsetHeight,
          }
        });
      }
    });
    return detectionObjects;
  }

  showDetections = (predictions) => {
    // This loop is called for EVERY video frame.  
    // Things that happen here will affect user experience
    const boxes = predictions[6].arraySync();
    const scores = predictions[3].arraySync();
    const classes = predictions[1].dataSync(); 
    
    try {
      // Consolidate results from ML into useable js object
      const detections = this.buildDetectedObjects(scores, boxes, classes);

      // Sort and concatente detections into rows and numeric values
      // push result to buffer in state
      this.pushAndShift(this.detectBPMonitor(detections));
      
      //buffer the data
      if(this.currentFrame < DAMPENER) {
        this.currentFrame++;
        return;
      } else {
        this.currentFrame = 0;
        this.processData(this.state.bufferedData);
        this.drawBoundingBoxes(detections);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // coalesce classified images into recognized values
  detectBPMonitor = (detections) => {
    // sort detections visually top to bottom
    let sortedDetections = detections.sort((a, b) => a.rect.top - b.rect.top);

    //console.log(detections);
    let rows = [];
    let result = {};
    let maxBottom = 0;
    let currentRow = 0;

    //stratify detections into rows
    sortedDetections.forEach((detection, i) => {
      // New row
      if (Math.abs(detection.rect.top) > maxBottom) {
        rows.push([]);
        currentRow = rows.length -1;
      }      

      maxBottom = Math.max(detection.rect.bottom, maxBottom);
      rows[currentRow].push(detection);
    });

    // make sure we have at least 3 rows
    for(var i = rows.length; i < 3; i++) {
      rows.push([]);
    }

    //sort LTR and concatenate labels together
    result.sys = rows[0].sort((a, b) => a.rect.left - b.rect.left).reduce((valueText, detected) => valueText + detected.label, "");
    result.dia = rows[1].sort((a, b) => a.rect.left - b.rect.left).reduce((valueText, detected) => valueText + detected.label, "");
    result.pulse = rows[2].sort((a, b) => a.rect.left - b.rect.left).reduce((valueText, detected) => valueText + detected.label, "");

    
    result.boundingBoxes = this.setBoundingBoxes(rows);
    
    // result is an object of systolic, diastolic, pulse rate values and associated arrays of each digit of each value
    return result;
  };

  capture = () => {
    let ctx = this.canvasRef.current.getContext("2d");    
    ctx.drawImage(this.videoRef.current, 0, 0, this.canvasRef.current.width, this.canvasRef.current.height );
    let image = this.canvasRef.current.toDataURL("image/jpeg");

    //tranid: this.props.tranid,
    const details = {
      detections: {
        "SYS":   (this.state.sys === undefined || this.state.sys === null || this.state.sys === "") ? 0 : this.state.sys, 
        "DIA":   (this.state.dia === undefined || this.state.dia === null || this.state.dia === "") ? 0 : this.state.dia,  
        "PULSE": (this.state.pulse === undefined || this.state.pulse === null || this.state.pulse === "") ? 0: this.state.pulse,  
      },
      rectangles: this.state.boundingBoxes,
      image: image,
      deviceName: this.state.device,
    };

    window.parent.postMessage({"type":"response", "response": details}, "*");
        
  };

  // Buffer rationalized values 
  pushAndShift = (latestData) => {
    const { bufferedData } = this.state;
    if(bufferedData.length >= DAMPENER) {
      bufferedData.shift();
    }
    bufferedData.push(latestData);
    this.setState({ bufferedData });
  }

  drawBoundingBoxes(detections) {
    const fontSize = 12;
    const ctx = this.canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.textBaseline = "top";
    const font = `600 ${fontSize}px helvetica`;
    ctx.font = font;

    detections.forEach((item) => {
      // Draw the bounding box.
      ctx.strokeStyle = "#00F260"; // #0575E6
      ctx.lineWidth = 2;

      ctx.strokeRect(item.rect.left, item.rect.top, item.rect.width, item.rect.height);

      // Draw the label background.
      ctx.fillStyle = "#00F260";

      // Draw the bounding box.
      ctx.fillRect(item.rect.left, item.rect.top, item.rect.width, item.rect.height * .1);

      // Draw the text last to ensure it's on top.
      ctx.fillStyle = "#000000";
      ctx.fillText(item.label, item.rect.left + ((item.rect.width - fontSize) / 2), item.rect.top + ((item.rect.height * .1) - fontSize) / 2);
    });
  }

  // find most common occurence of value in object
  mostMatchFunc = (haystack, needle) => {
    let obj = {}, most = { mostMatch: "", frequency: 0 };
    haystack.forEach((x,i) => {
      const v = x[needle];
      obj[v] ? obj[v]++ : (obj[v] = 1);
      if (obj[v] > most.frequency) {
        most.mostMatch = v;
        most.frequency = obj[v];
        //console.log(v);
        if(x.boundingBoxes) most.boxes = x.boundingBoxes;
      }
    });
    return most;
  };

  // Setting State from 10 data Bp Monitor
  processData(dataBuffer) {

    let sys = this.mostMatchFunc(dataBuffer,"sys");
    let dia = this.mostMatchFunc(dataBuffer,"dia");
    let pulse = this.mostMatchFunc(dataBuffer,"pulse");

    // Save the state that appears in input boxes on UI
    this.setState({
      sys: sys.mostMatch,
      dia: dia.mostMatch,
      pulse: pulse.mostMatch,
      boundingBoxes: sys.boxes,
    });
  }

  // collect bounding box from result
  setBoundingBoxes (rows) {
    let sysValueD = rows[0].map((arrayItem) => {
      return {"label": arrayItem.label,"rect":arrayItem.rect};
    });
    let diaValueD = rows[1].map((arrayItem) => {
      return {"label": arrayItem.label,"rect":arrayItem.rect};
    });
    let pulseValueD = rows[2].map((arrayItem) => {
      return {"label": arrayItem.label,"rect":arrayItem.rect};
    });

   return  {"sys":sysValueD, "dia":diaValueD, "pulse":pulseValueD};
  }

  render() {
    let device = false;
    const url = window.location.href;
    try {
      device = /device=(\w+)/.exec(url)[1];
    } catch (error) {}
    if (device === false) {
      return <h1>device parameter missing</h1>;
    }

    return (
      <>
        {this.state.isLoading && (
          <div className="splash">
            <h1><FontAwesomeIcon icon={solid("refresh")} className="fa-spin fa-2x" />  Loading....</h1>
          </div>
        )}
        {device && device === "bpmonitor" && (

        <div className="canvasBox" ref={this.canvasBox}>
          <div className="vidAndCanvasBox">
            <video className="videoFrame" autoPlay playsInline muted ref={this.videoRef} id="frame" />
            <canvas className="canvasFrame" ref={this.canvasRef} />
          </div>

          <div className="shutterButton" ref={this.shutterBox}>
            <input readOnly type="text"  placeholder="SYS" className="inputElements" defaultValue={this.state.sys} />
            <input readOnly type="text"  placeholder="DIA" className="inputElements" defaultValue={this.state.dia} />
            <input readOnly type="text"  placeholder="PULSE" className="inputElements" defaultValue={this.state.pulse} />
            <button 
              onClick={this.capture} 
              variant={"contained"} 
              className="btn-capture" 
              >
                <FontAwesomeIcon icon={solid("camera")} /> 
            </button>
          </div>
        </div>
      )}
    </>
    );
  }
}

export default BpMonitor;
