import React, { useState } from "react";
import { Accordion, AccordionHeader, AccordionItem, AccordionBody } from "reactstrap";

const defaultFaqs = {
  subheading: "Read This First",
  heading: "FAQS",
  description: "You can find answers to many of your questions here.",
  sections: [{
    title: "General Information",
    items: [
      {
        question: "What can RediMD doctors see me for?",
        answer: "<h4>Commonly Treatable Ailments include:</h4><ul><li>COLDS AND FLU</li><li>HEADACHE - migraines</li><li>SINUS AND THROAT ISSUES – sinus infection, laryngitis, sore throat</li><li>RESPIRATORY CONDITIONS – bronchitis, cough, upper respiratory infection</li><li>ALLERGIES – Itchy eyes, Itchy skin</li><li>EYE INFECTIONS – pink eye,</li><li>SKIN PROBLEMS – acne, eczema, ring worm, poison ivy/oak, rash</li><li>DIGESTIVE PROBLEMS – heartburn, constipation</li><li>SMOKING CESSATION</li><li>INTESTINAL PROBLEMS – diarrhea, nausea</li><li>MINOR ACHES AND PAIN</li><li>STRESS-RELATED PROBLEMS – anxiety, depression, insomnia</li><li>HIGH BLOOD PRESSURE (non-complicated)</li><li>HIGH BLOOD PRESSURE</li><li>DIABETES PROBLEMS (controlled, non-insulin)</li><li>HYPER- AND HYPO- THYROIDISM</li><li>HIGH CHOLESTEROL</li><li>OSTEOPOROSIS</li><li>AND MOST OTHER NON-EMERGENCY CONDITIONS</li></ul>",
      },
      {
        question: "What is RediMD?",
        answer: "With RediMD you can access a healthcare professional from your home, office, or on the go—24/7. Our Board-Certified physicians and licensed nurse practitioners can visit with you either by phone or secure video to help treat any non-emergency medical conditions. Our physicians can diagnose your symptoms, prescribe medication, and send prescriptions to your pharmacy of choice.",
      },
      {
        question: "How can RediMD help me?",
        answer: "RediMD provides you with access to board certified physicians and licensed nurse practitioners to diagnose symptoms, prescribe medication, and go over non-emergency health issues, from the comfort of your home or on the go, for only the cost of your co-payment. If you don't have insurance you can use the code REDIMD and schedule an appointment for only $40.00.",
      },
      {
        question: "What if I think I have COVID-19? Can RediMD’s doctors see me for COVID-19?",
        answer: "Yes. RediMD’s physicians have treated numerous patients that are experiencing symptoms of COVID-19. Our physicians follow the latest CDC guidelines and protocols. For more information, click here: <a href='https://www.cdc.gov/coronavirus/2019-nCoV/index.html'>https://www.cdc.gov/coronavirus/2019-nCoV/index.html</a>",
      },
      {
        question: "When should I use RediMD’s services?",
        answer: "You should schedule an appointment with RediMD when you need to talk to a doctor about non-emergency health issues like sinus problems, respiratory infections, allergies, flu symptoms, rashes, and many other illnesses. Our physicians will diagnose your symptoms, prescribe medication, and send prescriptions to your pharmacy of choice.",
      },
      {
        question: "Can I use RediMD for my family?",
        answer: "Yes. RediMD can treat patients from 4 years old and up. Anyone 17 and under must have a parent or guardian present during the visit. We make scheduling easy for the entire family!"
      },
      {
        question: "Can I use RediMD instead of my primary care physician?",
        answer: "RediMD can treat about 90% of the medical problems normally treated in a doctor’s office. Any issue requiring hands on, such as cuts needing sutures, needs to be done in person. Please see our list of common treatable aliments."
      },
      {
        question: "Can the doctors prescribe medications?",
        answer: "Yes. RediMD’s physicians can prescribe medications, the same way a doctor in person can. However, a prescription is not guaranteed. It is up to the Healthcare Professional to recommend the best treatment. Prescriptions will be electronically submitted to the pharmacy of your choice. RediMD doctors will not issue prescriptions for substances controlled by the DEA, any narcotic pain medications, and certain other drugs which may be harmful because of their potential for abuse."
      },
      {
        question: "How are prescriptions sent to the pharmacy?",
        answer: "Prescriptions are submitted electronically to the pharmacy of your choice."
      },
      {
        question: "Can my medical record from this visit be shared with my other doctors?",
        answer: "Yes. Upon your request, we will provide you with a copy of your medical record to forward to your other doctors."
      },
      {
        question: "Is RediMD for emergencies?",
        answer: "RediMD is only for non-emergency medical issues. Please dial 911 if you are having a medical emergency."
      },
      {
        question: "Can I be turned down for pre-existing conditions?",
        answer: "No, no one is turned away because of pre-existing conditions."
      },
    ],
  },
  {
    title: "About Virtual Appointments/Troubleshooting",
    items: [
      {
        question: "How do I schedule an appointment?",
        answer: "To schedule an appointment, log into your account at RediMD.com and click the 'Make an Appointment' button on the home page. You will be asked a series of questions related to the reason for your appointment, similar to the forms you would fill out at a doctor’s office. If you have any issues or questions, you can always call 1-866-989-2873 for assistance with scheduling a visit.",
      },
      {
        question: "Do I need a specific device or software to use the service?",
        answer: "RediMD’s video visits are compatible with all smart phones, tablets, iPads and computers with an internet connection and webcam capability. Phone calls are also available.",
      },
      {
        question: "Can I use the service from anywhere in the United States?",
        answer: "Yes. RediMD’s physicians are licensed in all 50 States.",
      },
      {
        question: "How long is the average appointment?",
        answer: "The average appointment is 10 minutes.",
      },
      {
        question: "What are RediMD’s hours?",
        answer: "RediMD visits are available 24 hours a day, 7 days a week.",
      },
      {
        question: "How do I get ready for my video appointment?",
        answer: "When you arrive for a scheduled video visit, you will be asked to test your audio and video quality. This page runs simple tests on both your audio and video quality. If both are functioning properly, your computer has passed the test and you can proceed with video visits.",
      },
      {
        question: "How soon can I see a doctor once I log in?",
        answer: "Members can schedule a visit with a RediMD provider in as soon as 15 minutes, depending on availability.",
      },
    ]
  },
  {
    title: "About RediMD Healthcare Providers",
    items: [
      {
        question: "Do I talk to a real doctor?",
        answer: "Yes. All RediMD healthcare professionals are Board Certified and licensed in all 50 states, Canada and Mexico, and have seen over half a million patients since our foundation in 2010. When you request a consult, you will be connected with a doctor licensed and practicing in your state."
      },
      {
        question: "Who are the RediMD doctors? ",
        answer: "We were founded in 2010 by a group of doctors working with Texas Tech University Health Science Center. We provide telemedicine in primary care and specialty care. All Healthcare Professionals at RediMD are Board Certified Physicians and Licensed Nurse Practitioners in all 50 states, Canada and Mexico."
      },
      {
        question: "What are the physician’s credentials?",
        answer: "RediMD uses Board Certified Physicians and Licensed Nurse Practitioners. As member, you can select which medical professional you want to use for your visit."
      },
      {
        question: "Where are the doctors located? Do RediMD’s doctors speak foreign languages?",
        answer: "RediMD offers healthcare in English and Spanish. If additional language communication is requested, you may have a family member join you on the visit to translate. Other foreign languages, are available via a translation service."
      },
      {
        question: "Can I choose between a male or female physician?",
        answer: "Yes. RediMD has male and female healthcare providers available, and will do ensure you are comfortable with your provider, depending on availability."
      },
    ]
  },
  {
    title: "Account and Billing Information",
    items: [
      {
        question: "Does RediMD accept insurance?",
        answer: "Yes, we accept most insurance plans in-network and most modern health insurance plans cover tele-medicine. "
      },
      {
        question: "Do I have to register and is there a fee for registration?",
        answer: "Yes, you can create an account on RediMD’s website for free. You are only responsible for your co-payment at the time you schedule the appointment."
      },
      {
        question: "How do I set up my account?",
        answer: "You will need your registration code to get started. Click on the <a href='#navbar'>Login/Sign up button</a> at the top of any page and follow the instructions."
      },
      {
        question: "How much does the visit cost?",
        answer: "You are only responsible for your copay and that depends upon your insurance.  If you are uninsured, you can use the REDIMD code and see a provider for only $40.00."
      },
      {
        question: "How do I pay for my visit?",
        answer: "RediMD uses PayPal to process payments. You can use Visa, Master Card, American Express, Discover, Debit and gift cards. RediMD does not accept cash or check payments."
      },
      {
        question: "How does RediMD protect my privacy?",
        answer: "Health records are encrypted and kept private and secure to protect members' personal information. Only members can determine who can see the information in their records. Your RediMD medical history is stored in our fully secured and encrypted HIPAA-compliant cloud based servers. By law, we can never share your information without your express permission."
      },
    ]
  },
  {
    title: "Conditions We Are Unable to Treat",
    items: [
      {
        question: "Emergency situations and conditions",
        answer: ""
      },
      {
        question: "Prescription of controlled substances",
        answer: ""
      },
      {
        question: "Services that require physical presence like sutures (stiches)",
        answer: ""
      },
      {
        question: "Services outside of the United States of America",
        answer: ""
      },
    ]
  }]
};

function Faqs (props){
  const [open, setOpen] = useState([]);
  const toggle = (id) => {
    if (open === id) {
      setOpen([]);
    } else {
      setOpen(id);
    }
  }

  // override defaults with prop if specified
  const faqsData = (props.Faqs === undefined) ? defaultFaqs : props.Faqs;    
  
    return (
      <React.Fragment>
        <h2 className="w-100 text-center"> 
          {faqsData.subheading}
        </h2>
        <h1 className="w-100 text-center"> 
          {faqsData.heading}
        </h1>
        <h4 className="w-100 text-center"> 
          {faqsData.description}
        </h4>        
        <Accordion 
          className="w-75 mx-auto" 
          id="faqAccordion"
          open={open} toggle={toggle}
          >
          {faqsData.sections.map((section, idx) => {
            return (
              <div key={"section_"+idx.toString()}>
                <h3 className="p-2 pt-3 text-center">{section.title}</h3>
                {section.items.map((item, key) => {
                  return (
                      <AccordionItem key={idx.toString()+"_"+key.toString()}>
                        <AccordionHeader targetId={idx.toString()+"_"+key.toString()}>
                          <h4>{item.question}</h4>
                        </AccordionHeader>
                        <AccordionBody accordionId={idx.toString()+"_"+key.toString()}>
                          <p dangerouslySetInnerHTML={{ __html: item.answer }}/>
                        </AccordionBody>
                      </AccordionItem>
                  )
                })}
              </div>
            )
          })}
        </Accordion>
      </React.Fragment>
    )
  }

export default Faqs;