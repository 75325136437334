import React, { Component } from 'react';
import { Link } from "react-router-dom";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Faqs   from "../../components/Faqs/faqs";
import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";

class RediMDFaqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navClass : ""
        };
    }
    render() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });        
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                {/* <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} /> */}
                <NavbarPage navClass={this.state.navClass} />

                {/* section */}
                <Section/>
 
                {/* faqs */}
                <Faqs />

                {/* AI Amy */}
                <section className='text-center p-3'>
                    <h2>If you didn't find your answer here, try <Link to="/amy#">Ask Amy</Link></h2>
                </section>

                {/* social */}
                <Social />

                {/* footer */}
                <Footer/>
            </React.Fragment>
        );
    }
}

export default RediMDFaqs;