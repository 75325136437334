import React, { useState, useRef, useEffect  } from 'react';
import Styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import aiamy from "../../images/ai_amy.gif";
import { useCookies } from 'react-cookie';



function ChatBot() {
  const [conversation, setConversation] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["chatCounter"]);

  // use useEffect to run the function when the page loads for the first time to scroll to the top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  
  function saveCookie(cookieValue) {
    var tomorrow = new Date().getTime() + 60 * 60 * 24 * 1000;

    setCookie("chatCounter", cookieValue, {
      path: "/",
      expire: tomorrow,
      maxAge: 3600*24,
     });

    return cookieValue;
  }
  
  const scollToRef = useRef();

  const AmySpinner = Styled.div `
    color: white;
    z-index: 5000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  `;

  const generateResponse = async (message) => {
    try {
      let messageHistory = [];      
      let messageCount = (cookies.chatCounter) ? parseInt(cookies.chatCounter) : saveCookie(0);

      // limit questions to 4
      if(messageCount > 3) {
//          throw new Error("You have exceeded the number of questions allowed. Please contact customer service at 866-989-2873 or RediMDSupport@redimd.com");
      }
      
      // show the spinner
      setLoading(true);

      // push all the previous questions and answers
      conversation.map( (val, idx) => {
        messageHistory.push({
          "role" : "user",
          "content" : val.message 
        });

        messageHistory.push({
          "role" : "assistant",
          "content" : val.generatedResponse,
        });

        return true;
      });

      // push the current question
      messageHistory.push({
        "role" : "user",
        "content" : message,
      });
            
      const response = await Axios.post(
        process.env.REACT_APP_FQDN + "/api/gptRequest",
        { "messages":messageHistory },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const generatedResponse = response.data.choices[0].message.content.trim();
      setConversation([...conversation, { message, generatedResponse }]);
      
      // count the questions and limit to 3
      messageCount = ++messageCount;      
      saveCookie(messageCount);
      console.log("Tokens:",response.data.usage.total_tokens);

    } catch (error) {
        console.error(error);
        setConversation([
          ...conversation,
          { message, generatedResponse: 'Sorry, I could not generate a response at this time.'+error.message },
        ]);
    } finally {
      setLoading(false);
      scollToRef.current.scrollIntoView()
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userInput.trim() !== '') {
      setConversation([...conversation, { message: userInput, generatedResponse: '' }]);
      generateResponse(userInput);
      setUserInput('');
    }
  };

  const handleChange = (event) => {
    setUserInput(event.target.value);
  };

  const onSubmit = (token) => {
    document.getElementById("demo-form").submit();
  }

  return (
    <React.Fragment>
      {loading ? (
        <AmySpinner className="">
          <FontAwesomeIcon icon={faSpinner} spin size="3x"/>
        </AmySpinner>
      ) : (<i></i>)
      }
      <div className="container pt-5">
        <div className="row text-left">
            <div className="d-none d-md-block col-md-2">
                <img src={aiamy} className="m-auto" style={{maxWidth:"100%",objectFit : "cover"}} alt="AI Amy avatar"/>
            </div>
            <div className="col">
              <div className="row text-center pb-0">
                  <h4 className='small mx-auto'>I may not have a medical degree but I am a genius!</h4>
                  <h2 className="mx-auto">Ask <span className="text-primary">Amy</span> a Question</h2>
              </div>
                <ul style={{listStyle:"none",listStylePosition: "inside", marginLeft:"0"}} >
                    {conversation.map((item, index) => (
                    <li key={index}>
                        <div className="p-2 mr-auto mb-2 bg-warning rounded" style={{width:"66%"}}>
                            <strong>You: </strong><br/>
                            <span>{item.message}</span>
                        </div>
                        {item.generatedResponse && (
                        <>
                            <div className="p-2 mb-4 bg-info rounded" style={{marginLeft:"auto", width:"66%"}}>
                                <strong>AI Amy: </strong><br/>
                                {item.generatedResponse}
                            </div>
                        </>
                        )}
                    </li>
                    ))}
                </ul>
                <form onSubmit={handleSubmit} ref={scollToRef}>
                    <div className="input-group mb-0" id="amy">
                        <input className="form-control form-control-lg"  
                            placeholder='Type your question...' 
                            type="text" 
                            value={userInput} 
                            onChange={handleChange} />
                        <div className="input-group-append mt-1">&nbsp;&nbsp;
                            <button 
                            className='btn btn-lg btn-primary g-recaptcha'
                            data-sitekey ="6Lcxoh4mAAAAALvSwTM3pdcHd7a4Uge-hY2EY92X"
                            data-callback='onSubmit'
                            data-action='submit' 
                            type="submit"
                            >
                              Ask
                            </button>
                        </div>
                    </div>
                </form>
                <div className="row p-0 mt-0 mb-3 mx-auto" style={{lineHeight : ".9em"}}>
                  <i className='small text-muted'>
                    “Ask Amy” is an artificial intelligence (AI) feature and does not make medical diagnoses.  Seek the advice of a qualified medical professional if you are concerned about your health.  In case of emergency, you should dial 911 or visit your closest emergency room.
                    Information provided by “Ask Amy” is not intended to be a substitute for professional medical advice, diagnosis or treatment.  “Ask Amy” provides information to help guide your decision making based on readily available information about symptoms.  “Ask Amy” responses are generated based on patterns learned from a large dataset.  While efforts have been made to ensure accuracy, the responses may not always be based on information that is up-to-date, complete or applicable to your specific medical condition.  
                    We do not guarantee the accuracy, reliability, or timeliness of any information provided by “Ask Amy”.  We are not responsible or liable for any consequences resulting from the use of or reliance on “Ask Amy” responses.  You should not disregard a qualified medical professional’s advice - or delay or cancel a medical appointment - because you are relying on responses from “Ask Amy.”   By using “Ask Amy”, you acknowledge that you have read and understood this disclaimer and agree to use the information provided at your own discretion and risk. 
                  </i>
                </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ChatBot;